import React, { Component } from "react"
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'
import FixedMenu from "../components/FixedMenu" 
import ImageCard from "../components/ImageCard" 
import ItemCard from "../components/ItemCard" 
import SiteContext from "../context/SiteContext"
import SEO from "../components/seo"

class SystemsPage extends Component {

    static contextType = SiteContext;
    
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            id:'',
            subtitle:'',
            slug: '',
            summary: '',
            systems: [],
            subsystems: [],
            benefits: [],
            features: [],
            imageUrl: '',
            uses: '',
            downloads: [],
            level: '',
            parentSlug: '',
            systemFamilies:[],
            allSystems:[],
            results:[],
        }
    }
    
    componentDidMount() {
        // console.log('props', this.props.pageContext)
        
        let site = 'finestone'
        
        console.log('site', site)
        this.context.changeSite(site)

        const {
            title,
            id,
            slug,
            summary,
            imagePath,
            children
        } = this.props.pageContext.system

        const systems = []

        // console.log("SYSTEMS: ", systems);
        // console.log("CHILDREN: ", children);

        const systemFamilies = this.props.pageContext.systemFamilyData.data.craft.categories; 

        console.log('families', systemFamilies)

        this.props.pageContext.systemData.data.craft.entries.map(system => {
            // console.log('system', system)
            // console.log('slug', slug)
            return system.systemFamily.map(family => {
                // console.log('family', family)
                if (family.slug === slug) {
                    systems.push(system)
                }
            })
        })

        const allSystems = this.props.pageContext.systemData.data.craft.entries;

        let results = [];
        let numberOfRelatedSystems = 0;
        let subtitle = "";

        if (children && children.length){
            children.map(child => {
                systemFamilies.map( systemCat => {
                    if (child.id === systemCat.id){
                        //add number of systems related to this child system family
                        let numberOfRelatedSystems = 0;
                        allSystems.map( system => {
                            system.systemFamily.map ( fam => {
                                if (child.id === fam.id ){
                                    numberOfRelatedSystems++;
                                }
                            })
                        })
                        if (numberOfRelatedSystems === 1) {
                            systemCat['subtitle'] = numberOfRelatedSystems + " System";
                        } 
                        else if (numberOfRelatedSystems > 1) {
                            systemCat['subtitle'] = numberOfRelatedSystems + " Systems";
                        } 
                        else {
                            systemCat['subtitle'] = "";
                        }
                        results.push(systemCat);
                    }
                })
            })
        } else {
            allSystems.map( system => {
                system.systemFamily.map ( fam => {
                    if (id === fam.id ){
                        numberOfRelatedSystems++;
                    }
                })
            })
            if (numberOfRelatedSystems === 1) {
                subtitle = numberOfRelatedSystems + " System";
            } 
            else if (numberOfRelatedSystems > 1) {
                subtitle = numberOfRelatedSystems + " Systems";
            } 
        }

        this.setState({
            title,
            subtitle,
            id,
            slug,
            summary,
            imagePath,
            children,
            systems,
            systemFamilies,
            allSystems,
            results
        }, () => {
            console.log(this.state)
        })
    }

    render() {

        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <>
                    <SEO title="Systems" />
                        <div className="wrapper">       
                            <div className="content-wrapper app-header-style-image">
                                <div className="app-header">
                                    <div className="app-header-bg" style={{backgroundImage: `url(${this.state.imagePath}?width=940)` }}></div>
                                    <div className="app-header-content">
                                        <h1 className="app-header__title title--left">{this.state.title}</h1>
                                        <div className="app-header__subtitle">{this.state.subtitle}</div>
                                    </div>     
                                    {/* <div className="large-text" dangerouslySetInnerHTML={{ __html: this.state.summary }}></div> */}
                                </div>
                            
                                { this.state.results.length > 0 
                                ?
                                    <div className="image-card__wrapper">
                                        {this.state.results.map( result => {
                                            return (
                                            <ImageCard 
                                                imagePath={`url(${result.imagePath}?width=940)`} 
                                                url={`/systems/${result.slug}`}
                                                title={result.title}
                                                subtitle={result.subtitle}
                                            />)
                                        })}
                                    </div>
                                :
                                    <div className="item-card__wrapper">
                                        {this.state.systems.map(system => {
                                            return (
                                                <ItemCard 
                                                    imagePath={`url(${system.imagePath}?width=400)`}
                                                    url={`/system/${system.slug}?fromUrl=${this.state.slug}&fromTitle=${this.state.title}`}
                                                    title={system.title}
                                                    viewType="System"
                                                />
                                            )
                                        })}
                                    </div>   
                                }
                            </div>
                            <FixedMenu active="systems" site={siteContext.site} /> 
                        </div>
                    </>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default SystemsPage